import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { Button } from "react-bootstrap";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import CheckedIcon from "../../assets/images/valid-blue.svg";
import { GetServerSideProps } from "next";
import { setUserProfile } from "../../actions";
import HeaderContainer from "../../containers/Header";
import { agencyLogin, getAgencyProfile } from "../../network-requests";
import ErrorIcon from "../../assets/images/error-icon.svg";
import MailIcon from "../../assets/images/email-icon-v2.svg";
import LockIcon from "../../assets/images/password-icon-v2.svg";
import WinleadsLogo from "../../assets/images/winleads-logo.png";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";
import { MaskedIcon } from "../../containers/NavBar";
import eyeSvg from "../../assets/images/eye.svg";
import styled from "styled-components";

const LinksContainer = styled.div`
	display: flex;
	gap: 10px;
`;


const WinleadsLoginPage = ({ seo }) => {
	const { t } = useTranslation("login-page");
	const { t: t2 } = useTranslation("steps");
	const { t: t3 } = useTranslation("common")

	const dispatch = useDispatch();
	const router = useRouter();
	const { locale } = router;

	const { RouterLink, routerPush } = useLinkTranslation();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email(t("p.invalid-email"))
			.required(t("email.required")),
		password: Yup.string().required(t("p.required")),
	});

	const handleLogin = ({ email, password }, actions) => {
		return new Promise(async (res, rej) => {
			try {
				const userProfile = await agencyLogin(email, password);
				dispatch(
					setUserProfile({
						...userProfile,
					})
				);
				window.localStorage.setItem(
					"user_profile",
					JSON.stringify(userProfile)
				);
				res("");
				// Store the scroll position before redirecting
				sessionStorage.setItem("scrollPosition", String(window.pageYOffset));

				const agency = await getAgencyProfile(locale);
				if (agency) {
					const agencyString = JSON.stringify(agency);
					localStorage.setItem("agency", agencyString);
					routerPush("/appointments", undefined, { locale });
				} else {
					routerPush("/register", undefined, { locale });
				}
			} catch (error) {
				actions.setErrors({
					password: t("error.message"),
				});
				rej(error);
			}
		});
	};

	// useEffect(() => {
	// 	window.scrollTo(0, Number(sessionStorage.getItem("scrollPosition")) || 0);
	// }, []);

	return (
		<div className="login-page-winleads d-flex flex-column align-items-center">
			<HeaderContainer title="" />
			<div
				style={{ marginTop: 90 }}
				className="align-items-center d-flex flex-column col-12 col-md-8 col-lg-6 col-xl-4"
			>
				<h1
					style={{ fontWeight: "bold", marginBottom: 40 }}
					className="page-title"
				>
					{t("title.login-to-your-account")}
				</h1>
				{/* <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
					<div className="page-subtitle" style={{ color: "var(--mainColor)" }}>
						{t("desc.dont-have-account")}
					</div>
					<RouterLink href="/signup">
						<a className="login-page-link ml-1">
							{t("desc.create-here-link")}{" "}
						</a>
					</RouterLink>
				</div> */}

				{/* <div
					style={{ marginTop: 30, gap: 15 }}
					className="d-flex flex-column flex-md-row justify-content-center align-items-center w-100"
				>
					<ButtonWithIcon
						iconSrc={Facebook}
						title={t("title.login-with-facebook")}
						titleMob="Facebook"
						color="#425893"
						onClick={() =>
							signIn("facebook", {
								callbackUrl: `${config.siteUrl}/auth-callback${
									payload ? `?payload=${payload}` : ""
								}`,
							})
						}
					/>

					<ButtonWithIcon
						iconSrc={Google}
						title={t("title.login-with-google")}
						titleMob="Google"
						color="#CD5642"
						onClick={() =>
							signIn("google", {
								callbackUrl: `${config.siteUrl}/auth-callback${
									payload ? `?payload=${payload}` : ""
								}`,
							})
						}
					/>
				</div> */}

				<Formik
					initialValues={{
						email: "",
						password: "",
					}}
					onSubmit={handleLogin}
					validationSchema={validationSchema}
				>
					{({ isSubmitting, errors, touched }) => (
						<Form className="w-100">
							<div className="d-flex flex-column form-input-block">
								{/* <Divider style={{ marginTop: 30, marginBottom: 15 }}>
									<span style={{ fontSize: 14, color: "#6C768F" }}>
										{t("text.or-use-your-email")}
									</span>
								</Divider> */}
								<label
									className="form-label"
									htmlFor="email"
									style={{ fontWeight: "bold" }}
								>
									{t("label.email-phone")}
									<span style={{ color: "#EF4A1E", marginLeft: 5 }}>*</span>
								</label>
								<div
									style={{
										position: "relative",
									}}
								>
									<img
										style={{ position: "absolute", top: 10, left: 12 }}
										src={MailIcon}
										alt="MailIcon"
									/>
									<Field
										className="form-input"
										name="email"
										type="email"
										id="login.email"
										placeholder={t("placeholder.email-phone")}
										style={{
											width: "100%",
											marginTop: -7,
											paddingLeft: 40,
											border:
												errors.email && touched.email
													? "1px solid #EF4A1E"
													: "1px solid #8f99b4",
										}}
									/>
								</div>
								<div className="d-flex flex-column align-items-start">
									{errors.email && touched.email && (
										<div className="d-flex">
											<img
												src={ErrorIcon}
												alt="ErrorIcon"
												style={{
													marginRight: "6px",
													height: "18px",
													marginTop: "2px",
												}}
											/>
											<ErrorMessage
												className="form-error"
												component="div"
												name="email"
											/>
										</div>
									)}
								</div>
							</div>
							<div className="d-flex flex-column form-input-block">
								<label
									className="form-label"
									htmlFor="password"
									style={{ fontWeight: "bold" }}
								>
									{t("label.password")}
									<span style={{ color: "#EF4A1E", marginLeft: 5 }}>*</span>
								</label>
								<div
									style={{
										position: "relative",
									}}
								>
									<img
										style={{ position: "absolute", top: 12, left: 12 }}
										src={LockIcon}
										alt="LockIcon"
									/>
									<Field
										className="form-input form-input-error"
										name="password"
										id="login.password"
										type={isPasswordVisible ? "text" : "password"}
										placeholder={t("placeholder.password")}
										style={{
											width: "100%",
											marginTop: -7,
											paddingLeft: 40,
											border:
												errors.password && touched.password
													? "1px solid #EF4A1E"
													: "1px solid #8f99b4",
										}}
									/>
									<MaskedIcon
										selected={isPasswordVisible}
										icon={eyeSvg}
										size={18}
										style={{ position: "absolute", top: 12, right: 12 }}
										onClick={() => {
											setIsPasswordVisible((value) => !value);
										}}
									/>
								</div>
								<div className="d-flex flex-column align-items-start">
									{errors.password && touched.password && (
										<div className="d-flex">
											<img
												src={ErrorIcon}
												alt="ErrorIcon"
												style={{
													marginRight: "6px",
													height: "18px",
													marginTop: "2px",
												}}
											/>
											<ErrorMessage
												className="form-error"
												component="div"
												name="password"
											/>
										</div>
									)}
								</div>
							</div>
							{/* <RouterLink href="/agency/forgot-password">
								<a
									style={{
										textDecoration: "underline",
										color: "var(--colorBlue)",
										fontWeight: "bold",
									}}
								>
									{t("link.remind-password")}
								</a>
							</RouterLink> */}
							<Button
								style={{ fontWeight: "bold", background: "#FC704C", borderColor: "#FC704C" }}
								className="form-button mt-4"
								block
								type="submit"
							>
								{isSubmitting ? t("button.loading") : t("button.login")}
							</Button>
						</Form>
					)}
				</Formik>
			</div>

			{/* <FooterContainer /> */}
			<div className="short-footer d-flex justify-content-center">
				<p>
					<span style={{ color: "#71849C" }}>© Winleads</span>{" "}
					<span style={{ color: "#71849C" }}>
						{new Date().getFullYear()} .
					</span>
				</p>
				<LinksContainer>
					{/* <RouterLink href={"/blogs"}>
						<a target="_blank">Blog</a>
					</RouterLink>
					<span style={{ color: "#D2D5DE" }}>|</span> */}
					<RouterLink href={"/privacy-policy"}>
						<a style={{ color: "#71849C", marginLeft: "8px" }} target="_blank">{t3("label.privacy")} .</a>
					</RouterLink>
					<RouterLink href={"/terms-and-conditions-agent"}>
						<a style={{ color: "#71849C" }} target="_blank">{t3("label.terms")}</a>
					</RouterLink>
				</LinksContainer>
			</div>

			{/* <div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					marginTop: 20,
					cursor: "pointer",
					color: "var(--colorBlue)",
					fontWeight: "800",
				}}
				onClick={() => {
					router.back();
				}}
			>
				<MaskedIcon
					selected
					icon={backArrow}
					size={16}
					style={{ marginTop: 6, marginRight: 8, marginLeft: 0 }}
				/>
				{t("button.back")}
			</div> */}
		</div>
	);
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
	const namespaces = ["login-page", "header", "steps", "common"];
	const _serverSideTranslations = await serverSideTranslations(
		locale,
		namespaces
	);
	return {
		props: {
			..._serverSideTranslations,
			_nextI18Next: {
				..._serverSideTranslations._nextI18Next,
				initialI18nStore: {
					..._serverSideTranslations._nextI18Next.initialI18nStore,
					[locale]: {
						..._serverSideTranslations._nextI18Next.initialI18nStore[locale],
					},
				},
			},
		},
	};
};

export default WinleadsLoginPage;
